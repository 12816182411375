export const getApiHost = () => {
    if (typeof window === 'undefined') return '';

    const hostname = window.location.hostname.split('.');
    if (hostname.length > 1) {
        hostname.unshift('api');
    } else {
        hostname.unshift('glean');
        hostname.unshift('api');
    }
    const apiHost = hostname.filter((path) => path !== 'www').join('.');

    return `${window.location.protocol}//${apiHost}`;
};

export const getAppHost = (path = '') => {
    if (typeof window === 'undefined') return '';

    const hostname = window.location.hostname.split('.');
    if (hostname.length > 1) {
        hostname.unshift('app');
    } else {
        hostname.unshift('glean');
        hostname.unshift('app');
    }
    const appHost = hostname.filter((path) => path !== 'www').join('.');

    return `${window.location.protocol}//${appHost}${path}`;
};

export const getSiteHost = (path: string = '') => {
    if (typeof window === 'undefined') return '';

    const siteHost = window.location.hostname;

    if (path !== '') {
        path = path.charAt(0) === '/' ? path : `/${path}`;
    }

    return `${window.location.protocol}//${siteHost}${path}`;
};
